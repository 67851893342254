
import RectItem from '@/components/RectItem.vue';
import { defineComponent, reactive } from 'vue';
export default defineComponent({
  components: {
    RectItem
  },
  data() {
    return {
      first: '',
      end: ''
    }
  },
  setup() {
    let list:Array<any> = reactive([0, 1, 2, 3, 4, 5, 6])
    return {
      list
    }
  },
  methods: {
    allowDrop (e:any) {
      e.preventDefault();
    },
    dragenter(e:any) {
      e.preventDefault();
    },
    dragstart (e:any, index:any) {
      /* 
        获取被拖动元素的位置 e.x e.y 元素
        被拖动元素的大小 e.target.width e.target.height

      */
     e.dataTransfer.setData("Text", '1');
     this.first = index
    },
    drop (e:any, index: any) {
      e.preventDefault()
      this.end = index.toString()
      console.log(e)
      this.chengju()
    },
    chengju () {
      const list = JSON.parse(JSON.stringify(this.list))
      if (this.first > this.end) {
        for (let i:any = this.end; i<=this.first; i++) {
          this.list.splice(i, 1, list[i-1])
        }
        this.list[this.end as any] = list[this.first]
      }
      if (this.first < this.end) {
        for(let i:any = this.first; i<=this.end; i++) {
          this.list.splice(i, 1, list[i+1])
        }
        this.list[this.end as any] = list[this.first]
      }
    }
  }
})
