import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onDrop"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RectItem = _resolveComponent("RectItem")!

  return (_openBlock(), _createBlock(_component_RectItem, {
    margins: "10",
    paddings: "10",
    height: "400"
  }, {
    default: _withCtx(() => [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
          return (_openBlock(), _createElementBlock("li", {
            onDrop: ($event: any) => (_ctx.drop($event, index)),
            onDragenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dragenter($event))),
            onDragover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.allowDrop($event))),
            key: index
          }, [
            _createVNode(_component_RectItem, {
              onDragstart: ($event: any) => (_ctx.dragstart($event, index)),
              onDrag: _ctx.drag,
              onDragend: _ctx.dragend,
              class: "mt",
              width: "30",
              draggable: "true",
              height: "30"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item), 1)
              ]),
              _: 2
            }, 1032, ["onDragstart", "onDrag", "onDragend"])
          ], 40, _hoisted_1))
        }), 128))
      ]),
      _createVNode(_component_RectItem, {
        onDragenter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dragenter($event))),
        width: "30",
        onDrop: _cache[3] || (_cache[3] = ($event: any) => (_ctx.drop($event))),
        onDragover: _cache[4] || (_cache[4] = ($event: any) => (_ctx.allowDrop($event))),
        height: "30"
      })
    ]),
    _: 1
  }))
}