
import { Options, Vue } from 'vue-class-component';
import DragItem from '@/components/DragItem.vue';
import { defineComponent, reactive, ref} from 'vue';
  // @Options({
  //   components: {
  //     DragItem
  //   }
  // })
export default defineComponent({
    components: {
      DragItem
    },
  data() {
    return {

    }
  }
})
